
<template>
  <div id="post-app" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
    <vs-sidebar class="items-no-padding" parent="#post-app" :click-not-close="clickNotClose" :hidden-background="clickNotClose" v-model="isSidebarFilterActive">
        <component :is="scrollbarTag" class="post-scroll-area" :settings="settings" :key="$vs.rtl">
            <foto-filters @closeSidebar="toggleFilterSidebar"
            @openAlbumAdd="albumOpenAddSidebar" 
            :postFilter="postFilter"
            :Search="search"
            :dateMulai="mulai"
            :dateSampai="sampai"></foto-filters>
        </component>
    </vs-sidebar>

    <div :class="{'sidebar-spacer': clickNotClose}"  id="post-load" class="no-scroll-content border border-r-0 border-b-0 border-t-0 border-solid d-theme-border-grey-light no-scroll-content vs-con-loading__container">

      <div class="flex d-theme-dark-bg items-center border border-l-0 border-r-0 border-t-0 border-solid d-theme-border-grey-light">

          <!-- TOGGLE SIDEBAR BUTTON -->
          <feather-icon class="md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer" icon="MenuIcon" @click.stop="toggleFilterSidebar(true)" />

          <!-- SEARCH BAR -->
          <vs-input icon-no-border size="large" icon-pack="feather" icon="icon-search" placeholder="Search..." v-model="search" v-on:keyup.enter="filterData()" class="vs-input-no-border vs-input-no-shdow-focus w-full" />
          
      </div>

      <div class="flex items-center flex-wrap justify-between py-2 md:px-8 px-4">
        <div class="flex">
          <flat-pickr format="yyyy-MM-dd" :config="configFromdateTimePicker" v-model="mulai" placeholder="Mulai" @on-change="onFromChange" class="mr-4" style="width:100px;height: 32px;"/>
          <flat-pickr format="yyyy-MM-dd" :config="configTodateTimePicker" v-model="sampai" placeholder="Sampai" @on-change="onToChange" class="mr-2" style="width:100px;height: 32px;"/>
          <feather-icon @click="filterData" icon="ArrowRightIcon" class="cursor-pointer" svg-classes="h-7 w-7"></feather-icon>
        </div>
        <div class="flex items-center">
          <div class="dropdown-button-container">
            <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
              <feather-icon icon="CodeIcon" class="cursor-pointer" svg-classes="h-7 w-7"></feather-icon>
              <vs-dropdown-menu>
                <vs-dropdown-item v-for="p in postPage" :key="p" @click.stop="nextData(p)" :disabled="p === postCurrentPage">
                  {{ p }}
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>


          <feather-icon icon="PlusIcon" class="cursor-pointer ml-5" svg-classes="h-7 w-7" @click="addUpdateSidebar()"></feather-icon>
        </div>
      </div>

      <!-- FOTO LIST -->
      <component :is="scrollbarTag" id="post-load" class="post-content-scroll-area" :settings="settings" ref="postListPS" :key="$vs.rtl">

          <transition-group name="list-enter-up" tag="ul" appear>
            <li
              class="cursor-pointer"
              v-for="(post, index) in postList"
              :key="String(postFilter) + String(post.ID)"
              :style="[{transitionDelay: (index * 0.1) + 's'}]"
              @click="viewUpdateSidebar(post)">

              <foto-item 
                :data="post" 
              />
            </li>
          </transition-group>
      </component>
      <!-- /FOTO LIST -->
    </div>

    <foto-album-add 
      :isAlbumAddActive="isAlbumAddActive" @albumCloseAdd="albumCloseAddSidebar"
    ></foto-album-add>
    <foto-update 
      :isUpdateActive="isUpdateActive"
      @closeUpdate="closeUpdateSidebar"
      :data="dataFoto">
    </foto-update>
  </div>
</template>

<script>
import moduleFoto          from '@/stores/post/modulePost.js'
import FotoItem            from './FotoItem.vue'
import FotoFilters         from './FotoFilters.vue'
import FotoAlbumAdd        from './FotoAlbumAdd.vue'
import FotoUpdate          from './FotoUpdate.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import flatPickr          from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {
    FotoItem,
    FotoFilters,
    FotoAlbumAdd,
    FotoUpdate,
    VuePerfectScrollbar,
    flatPickr
  },
  data () {
    return {
      dataFoto       : {},
      isAlbumAddActive  : false,
      isAccountAddActive   : false,
      currFilter           : '',
      clickNotClose        : true,
      displayPrompt        : false,
      isSidebarFilterActive: true,

      isUpdateActive       : false,
      albumUpdate       : 0,

      settings : {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.30
      },

      configFromdateTimePicker: {
        minDate: null,
        maxDate: null
      },
      configTodateTimePicker: {
        minDate: null
      },
      changeMulai: false,

      // Param
      search: '',
      mulai: null,
      sampai: null,
    }
  },
  watch: {
    isAccountAddActive (value) {
      if (!value) this.showThread = false
    },
    isUpdateActive (value) {
      if (!value) this.showThread = false
    },
    postFilter () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#post-load',
        type: 'sound',
        scale: 0.5
      })
      const scroll_el = this.$refs.postListPS.$el || this.$refs.postListPS
      scroll_el.scrollTop = 0
      const obj = {
        type: 'foto',
        filter_type: this.$route.params.filter_type,
        filter: this.$route.params.filter,
        search: this.search,
        dateMulai: this.mulai,
        dateSampai: this.sampai
      }
      this.$store.commit('foto/SET_NULL_TASKS')
      this.$store.dispatch('foto/fetchPost', obj)
      if (this.$store.state.foto.posts.length > 0 || this.$store.state.foto.posts.length === 0) {
        setTimeout(() => {
          this.$vs.loading.close('#post-load > .con-vs-loading')
        }, 2000)
      }
      this.toggleFilterSidebar()
    },
    windowWidth () {
      this.setSidebarWidth()
    }
  },
  computed: {
    postFilter ()   { return this.$route.params.filter },
    postList ()     { return this.$store.state.foto.posts      },
    postTags () {
      return this.$store.state.foto.Tags
    },
    postAlbums () {
      return this.$store.state.foto.postAlbums
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    windowWidth ()  { return this.$store.state.windowWidth },
    postTotal () {
      return this.$store.state.foto.total
    },
    postTotalSatu () {
      return this.$store.state.foto.total + 1
    },
    postLast () {
      return this.$store.state.foto.last
    },
    postPerPage () {
      return this.$store.state.foto.per_page
    },
    postCurrentPage () {
      return this.$store.state.foto.current_page
    },
    postPage () {
      const page = []
      const number = this.$store.state.foto.total / this.$store.state.foto.per_page
      var i
      for (i=0; i < number; i++) {
        page.push(i+1)
      }
      return page
    },
    postTotalPage () {
      let number = this.$store.state.foto.total / this.$store.state.foto.per_page
      if (number < 1) {
        number = 1
      }
      return number.toFixed(0)
    }
  },
  created () {
    this.$vs.loading()
    this.$store.registerModule('foto', moduleFoto)
    this.setSidebarWidth()

    const obj = {
      type: 'foto',
      filter_type: this.$route.params.filter_type,
      filter: this.$route.params.filter
    }
    // Fetch
    this.$store.dispatch('foto/fetchPost', obj)
    this.$store.dispatch('foto/fetchAlbum')
    this.$store.dispatch('foto/fetchTag')
    if (this.$store.state.foto.posts.length > 0 || this.$store.state.foto.posts.length === 0) {
      setTimeout(() => {
        this.$vs.loading.close()
      }, 2000)
    }
  },
  beforeUpdate () {
    this.currFilter = this.$route.params.filter
  },
  beforeDestroy () {
    this.$store.unregisterModule('post')
  },
  mounted () { 
  },
  methods: {
    onFromChange(selectedDates, dateStr) {
      this.changeMulai = true
      this.$set(this.configTodateTimePicker, 'minDate', dateStr)
    },
    onToChange(selectedDates, dateStr) {
      if (this.changeMulai != false) {
        this.$set(this.configFromdateTimePicker, 'maxDate', dateStr)
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Pilih tanggal mulai',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
      
    },
    setSidebarWidth () {
      if (this.windowWidth < 992) {
        this.isSidebarFilterActive = this.clickNotClose = false
      } else {
        this.isSidebarFilterActive = this.clickNotClose = true
      }
    },
    toggleFilterSidebar (value = false) {
      if (!value && this.clickNotClose) return
      this.isSidebarFilterActive = value
    },
    albumOpenAddSidebar() {
      this.isAlbumAddActive = true
    },
    albumCloseAddSidebar () {
      this.isAlbumAddActive = false
    },
    addUpdateSidebar() {
      this.dataFoto = {}
      this.isUpdateActive = true
    },
    viewUpdateSidebar(data) {
      this.dataFoto = data
      this.isUpdateActive = true
    },
    closeUpdateSidebar () {
      this.isUpdateActive = false
    },
    nextData (page) {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#post-load',
        type: 'sound',
        scale: 0.5
      })
      const scroll_el = this.$refs.postListPS.$el || this.$refs.postListPS
      scroll_el.scrollTop = 0
      
      const obj = {
        type: 'foto',
        filter_type: this.$route.params.filter_type,
        filter: this.$route.params.filter,
        search: this.search,
        dateMulai: this.mulai,
        dateSampai: this.sampai,
        page: page
      }
      this.$store.commit('foto/SET_NULL_TASKS')
      this.$store.dispatch('foto/fetchPost', obj)
      if (this.$store.state.foto.posts.length > 0 || this.$store.state.foto.posts.length === 0) {
        setTimeout(() => {
          this.$vs.loading.close('#post-load > .con-vs-loading')
        }, 2000)
      }
    },
    filterData () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#post-load',
        type: 'sound',
        scale: 0.5
      })
      const scroll_el = this.$refs.postListPS.$el || this.$refs.postListPS
      scroll_el.scrollTop = 0

      this.$store.commit('foto/', this.$route.params.filter)
      const obj = {
        type: 'foto',
        filter_type: this.$route.params.filter_type,
        filter: this.$route.params.filter,
        search: this.search,
        dateMulai: this.mulai,
        dateSampai: this.sampai
      }

      this.$store.commit('foto/SET_NULL_TASKS')
      this.$store.dispatch('foto/fetchPost', obj)
      if (this.$store.state.foto.posts.length > 0 || this.$store.state.foto.posts.length === 0) {
        setTimeout(() => {
          this.$vs.loading.close('#post-load > .con-vs-loading')
        }, 2000)
      }
    }
  }
}

</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/post.scss";
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, .2);
  }
}
.total-slider {
  min-width: unset;
}
</style>
